













































































































































































































































































































































































































































































































.iban {
  display: flex;
  align-items: center;

  i {
    font-size: 24px;
  }

  .el-icon-circle-close {
    color: #f56c6c;
  }

  .el-icon-circle-check {
    color: #1f9f43;
  }
}
